<template>
  <div class="header-container">
    <div class="l-content">
    </div>
    <div class="r-content">
      <!-- 下拉菜单 -->
      <el-row>
        <el-col :span="8">
          <div class="screen">
            <FullScreenVue></FullScreenVue>
          </div>
        </el-col>
        <el-col :span="8">
          <el-dropdown @command="handleClick">
            <span class="el-dropdown-link">
              <img src="../assets/staffPhoto.png" alt="" class="user"/>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="clearLocalStorage">清除缓存</el-dropdown-item>
              <el-dropdown-item command="cancel">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import FullScreenVue from './FullScreen.vue'

export default {
  components: { FullScreenVue },
  data () {
    return {
    }
  },
  created: async function () {
  },
  methods: {
    changeJournal: function (val) {
      // 将值保存到sessionStorage中
      sessionStorage.setItem('journalId', val)
      // 获取当前的url
      const currentUrl = this.$route.path
      // 跳转到首页 (因为切换期刊时, 用户所选的菜单可能展示的不是这个期刊的内容)
      if (currentUrl !== '/SystemHome') this.$router.push('/SystemHome')
    },
    handleMenu () {
      this.$store.commit('collapseMenu')
    },
    handleClick (command) {
      if (command === 'clearLocalStorage') {
        localStorage.clear()
        location.reload()
      } else {
        this.$message({
          message: '登出',
          type: 'warning'
        })
        // 清除cookie
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('menu')
        // 跳转到登录页面
        this.$router.push('/login')
      }
    }
  },
  computed: {}
}
</script>

<style lang="less" scoped>
.header-container {
  background-color: #333;
  display: flex;
  height: 3.5vw;
  justify-content: space-between;
  align-items: center;

  .text {
    color: #fff;
    font-size: 16px;
    margin-left: 10px;
  }

  .r-content {
    width: 150px;

    .screen {
      line-height: 60px;
      font-size: 25px;
    }

    .user {
      margin-top: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
    }
  }
}
</style>
